import React, { useContext, useEffect, useState } from 'react';
import { initialNavigation } from '@components/Sidebar/navigation';
import { navigate } from 'gatsby';
import Sidebar from '@components/Sidebar';
import SidebarModal from '@components/Sidebar/SidebarModal';
import Header from '@components/Header';
import authProvider from '@data/authProviderService';
import congesService from '@data/congesService';
import Cookies from 'universal-cookie';
import NotificationContext from '@context/NotificationContext';
import UserContext from '@context/UserContext';
import { getPermissions } from '@utility/permissions';
import { ToastContainer } from 'react-toastify';
import { useLocation } from '@reach/router';
import { getFullname } from '@utility/Utils';
import { setUser, ErrorBoundary } from '@sentry/gatsby';

import ImpersonateBanner from '../components/Impersonate';
import { cookiesOption } from '../utility/Utils';
import { QueryProvider } from '../providers/QuerProvider';

const Index = ({ children }) => {
  const [navigation, setNavigation] = useState(initialNavigation);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getValidationCount } = useContext(NotificationContext);
  const { setCurrentUser, currentUser, isImpersonate } =
    useContext(UserContext);
  const location = useLocation();

  const handleNavigation = (item) => {
    navigate(`${item.navigate}`);
    setSidebarOpen(false);
  };

  const initData = async () => {
    let jwt = '';
    const cookies = new Cookies();
    if (process.env.GATSBY_APP_ENV_TYPE === 'LOCAL') {
      const response = await authProvider.post('/auth/login', {
        email: process.env.GATSBY_APP_EMAIL,
        password: process.env.GATSBY_APP_PASSWORD,
      });
      jwt = response.data.access_token;
      cookies.set('jwt', response.data.access_token);
    } else {
      jwt = cookies.get('jwt', cookiesOption);
    }

    if (jwt) {
      const res = await congesService.get(
        '/v1/users/current?withUserLeaveCounts=1&withProfile=1&withSite=1&withClient=1&withOpenDays=1&withFutureLeaves=1&withManagers=1'
      );

      const nav = navigation.map((nav) => {
        return {
          ...nav,
          hasAccess: getPermissions(nav.name, res.data.data),
          current: nav.navigate === location.pathname,
        };
      });
      setNavigation(nav);

      const isAllowed = nav.find((n) => {
        return n.navigate === location.pathname;
      })?.hasAccess;

      if (!isAllowed) {
        if (location.pathname !== '/request/') {
          navigate('/');
        }
      }

      const user = {
        ...res.data.data,
        fullname: getFullname(res.data.data),
        user_leave_counts: res.data.data.futureLeaves,
      };

      getValidationCount(res.data.data);

      setCurrentUser(user);
      return res.data.data;
    } else {
      window.location.href = process.env.GATSBY_PORTAIL_URL;
    }
  };

  useEffect(() => {
    initData().finally(() => setIsLoading(false));
    setUser({
      id: currentUser?.id,
      email: currentUser?.email,
      username: getFullname(currentUser),
    });
  }, []);

  useEffect(() => {
    setNavigation((prevState) => {
      return prevState.map((nav) => {
        return {
          ...nav,
          current: nav.navigate === location.pathname,
        };
      });
    });
  }, [location.pathname]);

  const layoutInpersonnate = isImpersonate ? 'mt-[37px]' : 'mt-[0px]';
  return isLoading ? (
    ''
  ) : (
    <QueryProvider>
      <ErrorBoundary>
        <ToastContainer />
        <SidebarModal
          handleNavigation={handleNavigation}
          navigation={navigation}
          setSidebarOpen={setSidebarOpen}
          sidebarOpen={sidebarOpen}
        />
        <div className='z-[100] fixed left-0 top-0 bg-white w-screen min-w-[37.5rem]'>
          <ImpersonateBanner />
          <Header
            currentUser={currentUser}
            navigation={navigation}
            setNavigation={setNavigation}
            setSidebarOpen={setSidebarOpen}
            sidebarOpen={sidebarOpen}
          />
        </div>
        <div
          className={`hidden min-w-[270px] md:fixed  md:inset-y-0 md:flex md:flex-col ${layoutInpersonnate} `}
        >
          <Sidebar
            handleNavigation={handleNavigation}
            navigation={navigation}
            setNavigation={setNavigation}
          />
        </div>
        <div>
          <div
            className={`flex flex-1 flex-col md:pl-[270px] ${layoutInpersonnate} `}
          >
            <main className='flex-1 mr-4'>
              <div className='py-6 xl:mx-10 mx-5'>
                <div className='mx-auto md:max-w-full lg:max-w-[84%] mt-[59px]'>
                  {children}
                </div>
              </div>
            </main>
          </div>
        </div>
      </ErrorBoundary>
    </QueryProvider>
  );
};

export default Index;
