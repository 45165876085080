import React from 'react';

const Badge = ({ value, color, className = '', labelClassName = '' }) => {
  let textColor = color?.replace(/0xFF|0xff/i, '#') ?? 'black';
  return (
    <div
      style={{ color: textColor, backgroundColor: textColor + '20' }}
      className={`rounded-full px-2 py-0.5 font-medium leading-5 ${className}`}
    >
      <span className={`truncate w-18 ${labelClassName}`}>{value}</span>
    </div>
  );
};

export default Badge;
