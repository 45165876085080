import React, { useContext } from 'react';
import Badge from '@components/UI/Badge';
import dayjs from 'dayjs';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useTranslation } from 'react-i18next';
import UserContext from '@context/UserContext';

import jwtDecode from 'jwt-decode';

dayjs.extend(isSameOrBefore);

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

//Render Image preview
export const renderFilePreview = (file, className = '') => {
  if (file.type.startsWith('image')) {
    return (
      <img
        className={className}
        alt={file.name}
        src={URL.createObjectURL(file)}
      />
    );
  } else {
    return (
      <span>
        <Badge
          value={file.type.slice(file.type.indexOf('/') + 1, file.type.length)}
          color={'0xFF3A4E48'}
          className='p-2'
        />
      </span>
    );
  }
};

//Convert the size of a media in bytes to a readable format
export const renderFileSize = (size) => {
  if (Math.round(size / 100) / 10 > 1000) {
    return `${(Math.round(size / 100) / 10000).toFixed(1)} Mb`;
  } else {
    return `${(Math.round(size / 100) / 10).toFixed(1)} Kb`;
  }
};

//get the extension of a media
export const getExtension = (format) => {
  const extension = format.slice(format.lastIndexOf('.') + 1, format.length);
  return extension;
};

//Capitalize the first letter of a string
export const capitalizeFirstLetter = (string) => {
  let newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const formatArrayWithZeroLast = (array) => {
  // Push all elements with order_appearance = 0 at the end of the array
  const zeroElements = array.filter(
    (element) => element.order_appearance === 0
  );

  const nonZeroElements = array
    .filter((element) => element.order_appearance > 0)
    .sort((a, b) => a.order_appearance - b.order_appearance);

  return [...nonZeroElements, ...zeroElements];
};

//Get Mime Types
export const getMimeType = (extension) => {
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'csv':
      return 'text/csv';
    case 'docx':
      return 'application/msword';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return 'image/*';
  }
};

//Concat names in order to have a fullname
export const getFullname = (user) => {
  return `${user?.lastname} ${user?.firstname}`;
};

//Concat classnames
export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

//Build an array of each dates between two dates
export const arrayBetweenTwoDates = (startDate, stopDate) => {
  const result = [];

  let date = dayjs(startDate);

  while (dayjs(date).isBetween(dayjs(startDate), dayjs(stopDate), null, '[]')) {
    result.push({
      date: dayjs(date).format('YYYY-MM-DD'),
    });
    date = dayjs(date).add(1, 'day');
  }
  return result;
};

export const formatPeriod = (startDate, endDate) =>
  `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format(
    'DD/MM/YYYY'
  )}`;

//Render a date with morning and afternoon
export const formatDate = (startDate, endDate) => {
  const { t } = useTranslation();

  const checkStartDate = (startDate) => {
    if (dayjs(startDate).hour() <= 12) {
      if (dayjs(startDate).hour() === 12) {
        return t('afternoon');
      } else {
        return t('morning');
      }
    } else {
      return t('afternoon');
    }
  };

  const checkEndDate = (endDate) => {
    if (dayjs(endDate).hour() <= 12) {
      return t('morning');
    } else {
      return t('afternoon');
    }
  };

  return (
    <>
      <span className='text-[#505050] text-xs'>
        {`${t('from')} ${dayjs(startDate).format('L')} ${checkStartDate(
          startDate
        )}`}
      </span>
      <span className='text-[#505050] text-xs'>
        {`${t('to')} ${dayjs(endDate).format('L')} ${checkEndDate(endDate)}`}
      </span>
    </>
  );
};

export const dateWithoutTz = (date) => date.split('.')[0];

export const flutterColorToHexa = (color) => {
  return color?.replace(/0xFF|0xff/i, '#') ?? 'transparent';
};

const DELAY_REFRESH_TOKEN = 60;

export const checkJWT = (jwt) => {
  const expirationDate = dayjs.unix(jwtDecode(jwt).exp);
  const diffInSecond = expirationDate.diff(dayjs(), 'second');
  if (diffInSecond > DELAY_REFRESH_TOKEN) return 'valid';
  return 'refreshable';
};

export const alphabeticalOrdering = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const guidingTool = function (w, d, s) {
  var a = d.getElementsByTagName('head')[0];
  var r = d.createElement('script');
  r.async = 1;
  r.src = s;
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = '3c4820d1a81c2f0b0de9a6f7a7ab16c1';
  a.appendChild(r);
};

export const filterResponseByInput = (inputValue, response) => {
  const inputValueFormated = inputValue.trim().toLowerCase();
  if (inputValue.length < 1) return response;

  const result = response.filter((res) =>
    res.name.toLowerCase().includes(inputValueFormated)
  );

  return result;
};

export const cookiesOption = {
  path: '/',
  domain: process.env.DOMAIN || 'localhost',
};

export const colorConvert = (color) => {
  if (typeof color === 'string' && color.startsWith('0x')) {
    return `#${color.slice(2)}`;
  }
  return color;
};

export function removeAccents(str) {
  if (typeof str !== 'string') return '';
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll(' ', '')
    .toLowerCase();
}

const filterPeriod = (filter) => {
  const res1 = {
    field: 'start_date',
    operator: '<=',
    value: dayjs(filter.value[1]).format('YYYY-MM-DDT00:00:00'),
  };
  const res2 = {
    field: 'end_date',
    operator: '>=',
    type: 'and',
    value: dayjs(filter.value[0]).format('YYYY-MM-DDT23:59:59'),
  };

  return [res1, res2];
};

export const filterBuilder = (filters) => {
  return filters
    .filter((filter) => filter.value.length > 0)
    .flatMap((filter) => {
      if (filter.type === 'period') {
        return filterPeriod(filter);
      } else {
        return {
          field: filter.name,
          operator: Array.isArray(filter.value) ? 'in' : 'like',
          value: Array.isArray(filter.value)
            ? filter.value
            : `%${filter.value}%`,
        };
      }
    });
};

export const sortBuilder = (field, direction) => {
  return [
    {
      field: field,
      direction: direction,
    },
  ];
};

export const getStatusFilterValue = (pathname, hash) => {
  if (pathname === '/sendToAccounting/') {
    return hash !== '#accountingProcessed'
      ? ['VALIDATED', 'SUBMITTED', 'SUBMITTED_TO_CANCELLATION']
      : ['TRANSMITTED'];
  }
  if (pathname === '/validation/') {
    return hash === '' ? ['SUBMITTED'] : [];
  }
  return [];
};

export const getValidationScopes = (hash, isDiffManagerLvl) => {
  const { currentUser } = useContext(UserContext);
  if (hash === '#validationToProcessed') {
    return [{ name: "alreadyTreatedByUser", parameters: [currentUser.id] }];
  } else if (hash === '#validationToCancel') {
    return [{ name: "waitingForUserCancelation" }];
  } else {
    return [{ name: isDiffManagerLvl ? "waitingForUserValidationOnDifferentLevel" : "waitingForUserValidation", parameters: [currentUser.id] }];
  };
};